<template>
  <div class="ct" id="t1">
          <div class="ct" id="t2">
            <div class="ct" id="t3">
              <div class="ct" id="t4">
                <section>  
                 <ul>
                   <a href="#t1"><li class="icon fa fa-home" id="uno"></li></a>
                   <a href="#t2"><li class="icon fa fa-user" id="dos"></li></a>
                   <a href="#t3"><li class="icon fa fa-image" id="tres"></li></a>
                   <a href="#t4"><li class="icon fa fa-phone" id="cuatro"></li></a>
                 </ul>
                  <!-- <div class="page" :style="{backgroundImage: 'url(' + baseAddress + backgroundImage + ')'}" id="p1"> -->
                  <div class="page" id="p1">
                    <li class="icon fa fa-home"><span class="title">{{preTitle}}</span><h4>{{title}}</h4><p>{{text}}</p><div class="primary-button"><a href="#t2">Chi sono</a></div></li>   
                  </div>
                  <div class="page" id="p2">
                    <li class="icon fa fa-user"><span class="title">Chi sono</span>
                    <div class="container">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="left-text">
                            <h4>{{bioTitle}}</h4>
                            <p v-html="bioText"></p>
                            <div class="primary-button">
                              <a href="#t3">Un pò di foto....</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="right-image">
                            <img :src="baseAddress + bioImg" alt="" width="370px">
                          </div>
                        </div>
                      </div>
                    </div>
                    </li>
                  </div>  
                  <div class="page" id="p3">
                    <li class="icon fa fa-image"><span class="title">Galleria</span>
                      <div class="container">
                        <div class="row">
                            <div v-for="(item, index) in albums" class="tab" :class="{'active': item.active}" @click="tabClick(item)" v-bind:key="index">{{item.name}}</div>
                          <!-- <div>
                            <b-tabs content-class="mt-3">
                              <b-tab :class="{'active': index === 0}" v-for="(item, index) in albums" :title="item.id"><p>{{item.name}}</p></b-tab>
                            </b-tabs>
                          </div> -->
                          <!-- <div class="albums-container">
                          <ul id="albums">
                            <li v-for="(item, index) in albums" :class="{'active': index === 1}">{{item.name}}</li>
                          </ul>
                        </div> -->
                          <!-- <div v-masonry transition-duration="0.3s" item-selector=".item" v-for="(album, index1) in albums" v-show="album.active" class="album-container" :data-album-len="album.images.length" v-bind:key="'det' + index1"> -->
                          <div v-masonry transition-duration="0.3s" item-selector=".item" v-for="(album, index1) in albums" v-show="album.active" class="album-container" :data-album-len="album.images.length" v-bind:key="'det' + index1">
                              <!-- <div v-masonry-tile v-for="(image, index2) in album.images" v-bind:key="'img' + index2" :class="'album-image'"> -->
                              <div v-masonry-tile v-for="(image, index2) in album.images" v-bind:key="'img' + index2" :class="'album-image'">
                              <div :class="{'col-md-3 col-md-offset-1': index2 % 3 === 0, 'col-md-3': index2 % 3 !== 0}">
                                <div class="project-item">
                                  <a :href="baseAddress + image" :data-lightbox="album.name"><img :src="baseAddress + album.imagesThumb[index2]"></a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <div v-for="(item, index) in galleryImages">
                          <div :class="{'col-md-3 col-md-offset-1': index % 3 === 0, 'col-md-3': index % 3 !== 0}">
                            <div class="project-item">
                              <a :href="baseAddress + item" data-lightbox="image-1"><img :src="baseAddress + galleryThumbnails[index]"></a>
                            </div> 
                          </div>
                        </div> -->

                          <!-- <div class="col-md-3 col-md-offset-1">
                            <div class="project-item">
                              <a href="img/item-01.jpg" data-lightbox="image-1"><img src="img/thumb-01.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="project-item">
                              <a href="img/item-02.jpg" data-lightbox="image-1"><img src="img/thumb-02.jpg"></a>  
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="project-item">
                              <a href="img/item-03.jpg" data-lightbox="image-1"><img src="img/thumb-03.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3 col-md-offset-1">
                            <div class="project-item">
                              <a href="img/item-04.jpg" data-lightbox="image-1"><img src="img/thumb-04.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="project-item">
                              <a href="img/item-05.jpg" data-lightbox="image-1"><img src="img/thumb-05.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="project-item last-project">
                              <a href="img/item-06.jpg" data-lightbox="image-1"><img src="img/thumb-06.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3 col-md-offset-1">
                            <div class="project-item last-project">
                              <a href="img/item-06.jpg" data-lightbox="image-1"><img src="img/thumb-06.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="project-item last-project">
                              <a href="img/item-06.jpg" data-lightbox="image-1"><img src="img/thumb-06.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="project-item last-project">
                              <a href="img/item-06.jpg" data-lightbox="image-1"><img src="img/thumb-06.jpg"></a>
                            </div>
                          </div>
                          <div class="col-md-3 col-md-offset-1">
                            <div class="project-item last-project">
                              <a href="img/item-06.jpg" data-lightbox="image-1"><img src="img/thumb-06.jpg"></a>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </li>
                  </div>
                  <div class="page" id="p4">
                   <li class="icon fa fa-phone"><span class="title">Contattami</span>
                    <div class="container">
                      <div class="row">
                        <!-- <div class="col-md-6">
                           <form id="contact" action="" method="post">
                              <div class="col-md-12">
                                <fieldset>
                                  <input name="name" type="text" class="form-control" id="name" placeholder="Your Name" required="">
                                </fieldset>
                              </div>
                              <div class="col-md-12">
                                <fieldset>
                                  <input name="email" type="email" class="form-control" id="email" placeholder="Email" required="">
                                </fieldset>
                              </div>
                              <div class="col-md-12">
                                <fieldset>
                                  <textarea name="message" rows="6" class="form-control" id="message" placeholder="Message" required=""></textarea>
                                </fieldset>
                              </div>
                              <div class="col-md-12">
                                <fieldset>
                                  <button type="submit" id="form-submit" class="btn">Send Message</button>
                                </fieldset>
                              </div>
                            </form>
                         </div> -->
                         <div class="col-md-12">
                           <div class="right-info">
                             <div class="info-container" style="width: 100%; overflow: hidden;">
                             <div class="info-left" style="width: 600px; float: left;">
                              <p>
                                <em>
                                  <a href="https://form.jotform.com/202914759845366" target="_blank">Prenota il tuo shooting con me!</a>
                              </em>                                     
                             </p>
                             <p>
                              <em>
                                  <!-- {{contactText3}}
                                  <br> -->
                                  <a :href="contactLink2" target="_blank">{{contactLinkText2}}</a>
                              </em>
                          </p>
                          <p>
                            <em>
                                <a href="https://www.amazon.it/hz/wishlist/ls/2RH40KD9LLKF0?ref_=wl_share" target="_blank">La mia WishList su Amazon</a>
                            </em>
                        </p>     
                        <p>
                          <em>
                              <a href="https://www.paypal.me/spicyroller" target="_blank">PayPal</a>
                          </em>
                      </p>    
                      <p>
                        <em>
                          {{contactText2}}
                          <br>
                          <a :href="contactLink">{{contactLinkText}}</a>
                      </em>
                         </p>                                                                                     
                              </div>
                              <div class="info-right" style="margin-left: 620px;">                                                                  
                                   <p>
                                    <em>
                                        {{contactText}}
                                        <br>
                                        <a :href="'mailto:' + contactEmail">{{contactEmail}}</a>
                                    </em>
                                </p>  
                                </div>
                              </div>
                              <ol class="social-icons">
                                <i><a :href="contactFacebook"><i class="fa fa-facebook"></i></a></i>
                                <i><a :href="contactInstagram"><i class="fa fa-instagram"></i></a></i>
                                <!-- <i><a href="#"><i class="fa fa-twitter"></i></a></i> -->
                                <!-- <i><a :href="contactLinkedin"><i class="fa fa-linkedin"></i></a></i> -->
                                <!-- <i><a href="#"><i class="fa fa-rss"></i></a></i>
                                <i><a href="#"><i class="fa fa-behance"></i></a></i> -->
                              </ol>
                            </div>
                         </div>
                      </div>
                    </div>
                   </li>
                  </div>  
                </section>
              </div>
            </div>
           </div>
          </div>
</template>


<script>
import jQuery from 'jquery';
import axios from 'axios';
//import VueMasonry from 'vue-masonry'

// var VueMasonryPlugin = window["vue-masonry-plugin"].VueMasonryPlugin
// Vue.use(VueMasonryPlugin)

//dev
//const baseAddress = 'https://localhost:44376/';
//prod
const baseAddress = 'https://www.spicyroller.it/';
//const baseAddress = '';

export default {
  name: 'HomePage',
  data() {
    return {
      baseAddress: baseAddress,
        //home
        preTitle: null,
        title: null,
        backgroundImage: null,
        text: null,
        //bio
        bioTitle: null,
        bioText: null,
        bioImg: null,
        //gallery
        albums: [],
        // galleryThumbnails: [],
        // galleryImages: [],
        //contact
        contactEmail: null,
        contactText: null,
        contactText2: null,
        contactLinkText: null,
        contactLink: null,
        contactText3: null,
        contactLinkText2: null,
        contactLink2: null,
        contactFacebook: null,
        contactInstagram: null,
        contactLinkedin: null
    }
  },
  mounted () {
        axios
            .get(baseAddress + 'api')
            .then(response => 
                {
                    //console.log(response.data)
                    this.preTitle = response.data.preTitle
                    this.title = response.data.title
                    //this.backgroundImage = response.data.backgroundImage
                    jQuery(document.getElementById('p1')).css({"background-image": "url("+ baseAddress + response.data.backgroundImage+")"});
                    //waitAWhile();
                    this.text = response.data.text
                }                
            )
        axios
            .get(baseAddress + 'api/bio')
            .then(response => 
                {
                    //console.log(response.data)
                    this.bioTitle = response.data.title
                    this.bioText = response.data.text
                    this.bioImg = response.data.image
                }                
            )
        axios
            .get(baseAddress + 'api/galleria')
            .then(response => 
                {
                    //console.log(response.data)
                    this.albums = response.data.albums
                    // this.galleryImages = response.data.images
                    // this.galleryThumbnails = response.data.imagesThumb
                    //this.galleryRows = galleryImages.length
                }                
            )
        axios
            .get(baseAddress + 'api/contatti')
            .then(response => 
                {
                    //console.log(response.data)
                    this.contactEmail = response.data.email
                    this.contactText = response.data.text
                    this.contactText2 = response.data.contactText2
                    this.contactLinkText = response.data.contactLinkText
                    this.contactLink = response.data.link
                    this.contactText3 = response.data.contactText3
                    this.contactLinkText2 = response.data.contactLinkText2
                    this.contactLink2 = response.data.link2
                    this.contactInstagram = response.data.instagram
                    this.contactLinkedin = response.data.linkedin
                    this.contactFacebook = response.data.facebook
                }                
            )
    },
  methods: {
        tabClick(element) {
            this.albums.forEach(function(item){
                item.active = false;
            });
            element.active = true;
        }
    }
}
</script>
