import Vue from 'vue'
import App from './App.vue'

//window.$ = window.jQuery = require('jquery');
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

require('bootstrap')
import './assets/styles/bootstrap.min.css';
import './assets/styles/bootstrap-theme.min.css';

import './assets/js/functions.js'
import './assets/js/plugins.js'

import './assets/styles/fontAwesome.css';
import './assets/styles/tooplate-style.css';

import HomePage from '@/components/HomePage.vue'
Vue.component('HomePage', HomePage)

import {VueMasonryPlugin} from 'vue-masonry';
Vue.use(VueMasonryPlugin)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')